import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGear, faShareNodes, faCircleInfo, faHouse, faCircleXmark } from '@fortawesome/free-solid-svg-icons'

library.add(faGear, faShareNodes, faCircleInfo, faHouse, faCircleXmark);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react'
import { Link } from 'react-router-dom'
import SectionTitle from './SectionTitle'

function About() {
    return (
        <div className="about">
            <div className="about-content">
                <SectionTitle title="About"></SectionTitle>
                <span className="about-detail">
                    <span>Welcome to twosplenty, helping you to be calm and present with breathing exercises. twosplenty uses a technique called box breathing. Box breathing has 4 stages (or sides):</span>
                    <div className="box-breathing-container">
                        <span></span>
                        <span className="box-breathing-side">breath in</span>
                        <span></span>
                        <span className="box-breathing-side">hold</span>
                        <span className="box-breathing">
                            <span className="box-breathing-animation"></span>
                        </span>
                        <span className="box-breathing-side">hold</span>
                        <span></span>
                        <span className="box-breathing-side">breathe out</span>
                        <span></span>
                    </div>
                    <span>You can change the timing for each side in <Link to="/settings"><u>settings</u></Link>.</span>
                </span>
            </div >
        </div>
    )
}

export default About
import React from 'react';

import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from 'next-share';
import SectionTitle from './SectionTitle';

const shareUrl = 'https://www.twosplenty.com/';
const shareQuote = 'two minute breathing exercise';
const shareHashTag = '#twosplenty';
const iconSize = 40;

function Share() {
    return (
        <div className="share">
            <SectionTitle title="Share" />
            <p className="share-detail">Select an icon below to share with your friends.</p>
            <div className="social-share">
                <span className="share-your-progress">
                    <FacebookShareButton
                        url={shareUrl}
                        quote={shareQuote}
                        hashtag={shareHashTag}
                    >
                        <FacebookIcon size={iconSize} round />
                    </FacebookShareButton>
                </span>
                <span className="share-your-progress">
                    <TwitterShareButton
                        url={shareUrl}
                        title={shareQuote}
                    >
                        <TwitterIcon size={iconSize} round />
                    </TwitterShareButton>
                </span>
                <span className="share-your-progress">
                    <WhatsappShareButton
                        url={shareUrl}
                        title={'twosplenty, box breathing for two minutes'}
                    >
                        <WhatsappIcon size={iconSize} round />
                    </WhatsappShareButton>
                </span>
                <span className="share-your-progress">
                    <LinkedinShareButton url={shareUrl}>
                        <LinkedinIcon size={iconSize} round />
                    </LinkedinShareButton>
                </span>
                <span className="share-your-progress">
                    <EmailShareButton
                        url={shareUrl}
                        subject={shareHashTag}
                        body={shareQuote}
                    >
                        <EmailIcon size={iconSize} round />
                    </EmailShareButton>
                </span>
            </div>
        </div>
    )
}

export default Share
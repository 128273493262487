import React, { useState, useEffect } from 'react'
import SectionTitle from './SectionTitle';

function Settings() {
    const [breatheIn, setBreatheIn] = useState(() => getLocalStorageOrDefault('breatheIn', 4));
    const [holdIn, setHoldIn] = useState(() => getLocalStorageOrDefault('holdIn', 4));
    const [breatheOut, setBreatheOut] = useState(() => getLocalStorageOrDefault('breatheOut', 4));
    const [holdOut, setHoldOut] = useState(() => getLocalStorageOrDefault('holdOut', 4));

    function getLocalStorageOrDefault(keyName, defaultPhaseLength) {
        return JSON.parse(localStorage.getItem(keyName) || defaultPhaseLength);
    }

    function decrement(state, setState) {
        if (state === 1) {
            return;
        }
        setState(state - 1);
    }

    function increment(state, setState) {
        if (state === 10) {
            return;
        }
        setState(state + 1);
    }

    function restoreDefaults(){
        setBreatheIn(4);
        setHoldIn(4);
        setBreatheOut(4);
        setHoldOut(4);
    }

    useEffect(() => {
        localStorage.setItem('breatheIn', JSON.stringify(breatheIn));
        localStorage.setItem('holdIn', JSON.stringify(holdIn));
        localStorage.setItem('breatheOut', JSON.stringify(breatheOut));
        localStorage.setItem('holdOut', JSON.stringify(holdOut));
    }, [breatheIn, holdIn, breatheOut, holdOut])

    return (
        <div className="settings">
            <SectionTitle title="Timings" />
            <div className="second-counts">
                <div className="settings-lines">
                    <div className="setting-line">
                        <div className="setting-content">Breathe In</div>
                        <div className="setting-value">
                            <span className="second-control minus-second-control" onClick={() => decrement(breatheIn, setBreatheIn)}>-</span>
                            {breatheIn}
                            <span className="second-control plus-second-control" onClick={() => increment(breatheIn, setBreatheIn)}>+</span>
                        </div>
                    </div>
                    <div className="setting-line">
                        <div className="setting-content">First Hold</div>
                        <div className="setting-value">
                            <span className="second-control minus-second-control" onClick={() => decrement(holdIn, setHoldIn)}>-</span>
                            {holdIn}
                            <span className="second-control plus-second-control" onClick={() => increment(holdIn, setHoldIn)}>+</span>
                        </div>
                    </div>
                    <div className="setting-line">
                        <div className="setting-content">Breathe Out</div>
                        <div className="setting-value">
                            <span className="second-control minus-second-control" onClick={() => decrement(breatheOut, setBreatheOut)}>-</span>
                            {breatheOut}
                            <span className="second-control plus-second-control" onClick={() => increment(breatheOut, setBreatheOut)}>+</span>
                        </div>
                    </div>
                    <div className="setting-line">
                        <div className="setting-content">Second Hold</div>
                        <div className="setting-value">
                            <span className="second-control minus-second-control" onClick={() => decrement(holdOut, setHoldOut)}>-</span>
                            {holdOut}
                            <span className="second-control plus-second-control" onClick={() => increment(holdOut, setHoldOut)}>+</span>
                        </div>
                    </div>
                </div>
                <div className="restore-default-settings" onClick={()=>restoreDefaults()}>
                    or <u>restore defaults</u>
                </div>
            </div>
        </div>
    )
}

export default Settings
import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Header() {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <header className="header">
            {!isOpen ?
                <span className="open-overlay" onClick={() => openNav()}>&#9776;</span>
                :
                <span className="open-overlay" onClick={() => closeNav()}><FontAwesomeIcon icon="circle-xmark" /></span>
            }
            <div id="header-nav" className="overlay">
                <div className="overlay-content" onClick={() => closeNav()}>
                    <Link className="header-item-container" to="/">
                        <FontAwesomeIcon icon="house" /><span className="header-text">Home</span>
                    </Link>
                    <hr className="hr-primary"></hr>
                    <Link className="header-item-container" to="settings">
                        <FontAwesomeIcon icon="gear" /><span className="header-text">Settings</span>
                    </Link>
                    <hr className="hr-primary"></hr>
                    <Link className="header-item-container" to="share">
                        <FontAwesomeIcon icon="share-nodes" /><span className="header-text">Share</span>
                    </Link>
                    <hr className="hr-primary"></hr>
                    <Link className="header-item-container" to="about">
                        <FontAwesomeIcon icon="circle-info" /><span className="header-text">About</span>
                    </Link>
                </div>
            </div>
            <Link className="header-link" to="/" onClick={() => closeNav()}>
                <span className="xx-large-font">twosplenty</span>
                <span className="small-font"> box breathing for two minutes</span>
            </Link>
        </header>
    )

    function openNav() {
        setIsOpen(true);
        document.getElementById("header-nav").style.height = "60vh";
    }

    function closeNav() {
        setIsOpen(false);
        document.getElementById("header-nav").style.height = "0%";
    }

}

export default Header
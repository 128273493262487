import { Outlet } from "react-router-dom";
import Header from './Header'

const Layout = () => {
    return (
        <>
            <Header />
            <div className="outlet flex-centered full-width">
                <Outlet />
            </div>
        </>
    )
};

export default Layout;
import React from 'react'

function SectionTitle(props) {
    return (
        <>
            <hr></hr>
            <div className="settings-instruction">{props.title}</div>
            <hr></hr>   
        </>
    )
}

export default SectionTitle
import './App.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Breathe from './components/Breathe';
import Layout from './components/Layout';
import Settings from './components/Settings';
import About from './components/About';
import Share from './components/Share';
import NoSleep from 'nosleep.js';
import { useEffect } from 'react';


var noSleep = new NoSleep();

document.addEventListener('click', function enableNoSleep() {
  document.removeEventListener('click', enableNoSleep, false);
  noSleep.enable();
}, false);

function App() {

  useEffect(() => {
    return () => {
      noSleep.disable();
      console.log("no sleep disabled");
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Breathe />} />
            <Route path="share" element={<Share />} />
            <Route path="settings" element={<Settings />} />
            <Route path="about" element={<About />} />
            <Route path="*" element={<Breathe />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );


}

export default App;

import React, { useState, useRef, useEffect } from 'react';
const instructions = ["breathe in", "hold", "out", "hold"];
let secondCountsPerPhase = [];
const wellDone = "well done!";

function Breathe() {
    let stateRef = useRef();
    [stateRef.instruction, stateRef.setInstruction] = useState();
    [stateRef.notStarted, stateRef.setNotStarted] = useState(true);
    [stateRef.intervalNumber, stateRef.setIntervalNumber] = useState();
    [stateRef.timerNumber, stateRef.setTimerNumber] = useState();

    useEffect(() => {
        return () => onStop(stateRef);
    }, []);

    return (
        <div className="flex-centered flex-column full-width full-height" onClick={() => { onClick(stateRef) }}>
            <span className="breathe-content">
                {(!stateRef.notStarted && stateRef.instruction !== wellDone) && <span id="breathe-dot" className="breathe-dot"></span>} 
                <span className="flex-centered flex-column full-height">
                    {stateRef.notStarted ? "tap to begin" : stateRef.instruction}
                </span>
            </span>
            <div className="sub-breathe-content">
                {(!stateRef.notStarted && stateRef.instruction !== wellDone) &&
                    <>
                        <div className="flex-centered">
                            <p className="small-font accent-colour-font fade-in">tap to stop</p>
                        </div>
                        <div className="progress-container fade-in">
                            <div className="progress-moved"></div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

function onClick(stateRef) {
    stateRef.setNotStarted(!stateRef.notStarted);
    if (stateRef.notStarted) {
        secondCountsPerPhase = [
            getOrDefaultPhaseLength('breatheIn', 4),
            getOrDefaultPhaseLength('holdIn', 4),
            getOrDefaultPhaseLength('breatheOut', 4),
            getOrDefaultPhaseLength('holdOut', 4),
        ]
        onStart(stateRef)
    } else {
        onStop(stateRef);
    }
}

function getOrDefaultPhaseLength(phaseName, defaultPhaseLength) {
    return JSON.parse(localStorage.getItem(phaseName) || defaultPhaseLength);
}

function onStart(stateRef) {
    stateRef.setNotStarted(false);
    stateRef.setInstruction(instructions[0]);
    var seconds = 1;
    var phase = 0;
    setTimeout(() => setAnimation(phase), 1);
    const intervalNumber = setInterval(() => {
        if (stateRef.notStarted === true) {
            return;
        }
        seconds += 1;
        if (seconds === (secondCountsPerPhase[phase] + 1)) {
            seconds = 1;
            phase = (phase + 1) % 4;
            setAnimation(phase);
        }
        stateRef.setInstruction(instructions[phase]);
    }, 1000);

    var countDownDate = new Date().getTime() + 120_000; // 2 mins from now, twosplenty!

    const timerNumber = setInterval(() => {
        if (stateRef.notStarted === true) {
            return;
        }
        var now = new Date().getTime();
        var distance = countDownDate - now;
        if (distance <= 0 && distance > -3000) {
            stateRef.setInstruction(wellDone);
        } else if (distance <= -3000) {
            onStop(stateRef);
            return;
        }
    }, 1000)

    stateRef.setTimerNumber(timerNumber);
    stateRef.setIntervalNumber(intervalNumber);
}


function onStop(stateRef) {
    if (!!stateRef.intervalNumber) {
        clearInterval(stateRef.intervalNumber);
    }
    if (!!stateRef.timerNumber) {
        clearInterval(stateRef.timerNumber);
    }
    stateRef.setInstruction('');
    stateRef.setNotStarted(true);
}

function setAnimation(phase){
    const element = document.getElementById("breathe-dot");
    const animation = `breathe-${phase} ${secondCountsPerPhase[phase]}s linear 0s 1`;
    if (element) {
        element.style.animation = animation;
    }
}


export default Breathe

